.navbar {
  align-items: stretch;


  height: 70px;
  /* position: -webkit-sticky;
  position: sticky; */
  top: 0px;
  z-index: 2;
  background-color: var(--navbarBGColor);
  padding: 0em !important;
  transition: box-shadow 0.5s ease-in-out;

  backdrop-filter: blur(10px);
  position: -webkit-sticky !important;
  position: sticky !important;
  animation: slideIn 1s ease forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.navbar .container {
  padding: 0;
  display: flex;

}

.scrolled {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.active {
  font-weight: 700;
}

.left-section,
.right-section {
  width: 150px;
}

.left-section {
  text-align: left;
  font-family: "Lato";
  font-weight: 900;
  line-height: 1em;
  font-size: 18px;
  color: var(--heroSectionTitleColor) !important;
  cursor: pointer;
}

.middle-section {
  display: flex;
  justify-content: center;
}

.nav-item {
  color: var(--quinaryColor);
}

.nav-item:hover {
  color: var(--quinaryColor);
  opacity: 0.75;
}

.nav-item.active {
  color: var(--quinaryColor);
  opacity: 0.75;
}

.right-section {
}

.icons-section {
  float: right;
  display: flex;
  height: 32px;
}

.nav-list li {
  display: flex;
  padding: 0 20px 0 20px;
}

.nav-list li:hover {
}

.nav-list li:first-child {
}

.nav-list li a {
  color: #03045e;
  align-self: center;
  text-decoration: none;
}

.nav-list li div {
  background-color: red;
}

.dark-mode-button {
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 5px;
  transition: background-color 0.1s ease;
  cursor: pointer;
  user-select: none;
  color: var(--quinaryColor);
  background-color: var(--darkModeButtonBGColor);
}

.qhlogo{
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.dark-mode-button:hover {
  background-color: var(--darkModeButtonHoverBGColor);
}

.new-navbar-items {
  display: flex;
  justify-content: center;
}

.nav-items-icons{
}





@media (max-width: 991.5px) {
  .navbar {
    padding: 5em;
    justify-content: space-between;
    
    
  }

  .container{
    margin-left: 2px;
  }

  .middle-section {
    background-color: var(--navbarDropDownBGColor);
 
  }

  .left-section {
    order: -2;
    justify-content: flex-start;
    width: min-content;
    margin-left: 1em;
    
  }

  .right-section {
    order: -1;
    
    justify-content: flex-start;
    position: relative;
    width: min-content;
    margin-left: auto;
    
  }
}

@media (max-width: 768px) {
  nav {
    padding: 0 1em 0 1em;
    
  }
}
