.App {
  text-align: center;
  background-color: var(--pageBGColor);

}

.page-content {
  transition: filter 0.25s ease-in-out;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Define the styles for the scrollbar thumb (the draggable handle) */
::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarColor); /* Color of the thumb */
  border-radius: 5px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbarTrackColor); /* Color of the track */
}


@media (max-width: 991.5px) {
  .page-content.active {
    filter: blur(5px) brightness(70%);
  }
}

.page-content > div {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.footer {
  background-color: #010127;
  padding: 100px;
}

.footer p {
  color: gray;
}

